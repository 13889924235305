import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import throttle from 'lodash.throttle';
import Link from 'components/common/Link';
import { Svg, NoticeBar } from 'kh-common-components';
import navigationLinksData from 'constants/homepage/global-navigation';
import { isClientSideRendering, isLpgDemergerOn } from 'constants/global';
import PropTypes from 'prop-types';

const getDeviceConfig = (width) => {
    if(width < 1024){
        return 'small';
    } else {
        return 'large';
    }
};

const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => isClientSideRendering ? getDeviceConfig(window.innerWidth) : null);
    
    useEffect(() => {
       
      const calcInnerWidth = throttle(function() {
        setBrkPnt(getDeviceConfig(window.innerWidth))
      }, 200); 
      window.addEventListener('resize', calcInnerWidth);
      return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);
  
    return brkPnt;
}

const NavigationItem = ({data, index, hidingClass}) => {

    const iconClass = data.svgIcon ? 'mer-global-nav__menu-item--icon' : '';
    const specialClass = data.specialClass ? data.specialClass : '';
    const submenuClass = data.submenuClass ? data.submenuClass : '';

    const activeSubMenuClass = 'mer-global-nav__submenu--isOpen';
    const desktopSearchClass = 'mer-global-nav__menu-link';

    const handleDesktopSearchClick = (e) => {
    
        let thisTarget = e.currentTarget;
        let target = e.target;
        let currentTarget = e.currentTarget.childNodes[1];

        if (target.classList.contains(desktopSearchClass)) {
            if (currentTarget.classList.contains(activeSubMenuClass)) {
                currentTarget.classList.remove(activeSubMenuClass);
            } else {
                currentTarget.classList.add(activeSubMenuClass);
            }
        }
    }

    const handleDropdownSubmenuClick = (e) => {

        e.preventDefault();

        let thisTarget = e.currentTarget;
        let thisTargetId = e.currentTarget.getAttribute('aria-controls');

        let scope = thisTarget.closest('[data-mer-global-nav="scope"]');
        let target = thisTarget.parentElement.nextElementSibling;

        let subMenus = scope.getElementsByClassName('mer-global-nav__submenu');
        let subMenuButtons = scope.getElementsByClassName('mer-global-nav__accordion-button');

        // sub menus
        for (let i = 0; i < subMenus.length; i++) {
            if (subMenus[i].getAttribute('id') !== thisTargetId) {
                subMenus[i].classList.remove(activeSubMenuClass);
            }
        }

        // sub menu buttons
        for (let i = 0; i < subMenuButtons.length; i++) {
            if (subMenuButtons[i].getAttribute('id') !== thisTargetId) {
                subMenuButtons[i].setAttribute('aria-expanded', false);
            }
        }

        if (target.classList.contains(activeSubMenuClass)) {
            thisTarget.setAttribute('aria-expanded', false);
            target.classList.remove(activeSubMenuClass);
        } else {
            thisTarget.setAttribute('aria-expanded', true);
            target.classList.add(activeSubMenuClass);
        }
    }

    if (isLpgDemergerOn && data.hiddenByLpgDemerger !== undefined && data.hiddenByLpgDemerger === true) {
        return null;
    } else if (data.specialClass === 'search') {
        return (
            <li className={`mer-global-nav__menu-item ${iconClass} mer-global-nav__menu-item--search`} 
                onClick={handleDesktopSearchClick} >
                <span className="mer-global-nav__menu-link">
                    <Svg title="Magnifying glass" 
                        icon="search"
                        aria-hidden="true" />
                    Search
                </span>
                <ul className="mer-global-nav__submenu mer-global-nav__submenu--right">
                    <li className="mer-global-nav__submenu-item">
                        <form method="get" action="/">
                            <div className="form-field">
                                <label className="mer-form__field-label" htmlFor="global-nav-search-field">
                                    Search
                                </label>
                                <div className="mer-form__field-action mer-form__field-action--flex">
                                    <div className="mdc-text-field mdc-text-field--outlined mdc-text-field--no-label mer-flex--auto">
                                        <input className="mdc-text-field__input" id="global-nav-search-field" name="s" autoComplete="off" />
                                        <div className="mdc-notched-outline mdc-notched-outline--no-label">
                                            <div className="mdc-notched-outline__leading"></div>
                                            <div className="mdc-notched-outline__trailing"></div>
                                        </div>
                                    </div>
                                    <button className="mer-button mer-button--primary">
                                        <Svg title="Magnifying glass" 
                                            icon="search"
                                            col="mer-fill--gs-white mer-svg-container--size-xs"
                                            aria-hidden="true" />
                                    </button>
                                </div>
                            </div>
                        </form>
                    </li>
                </ul>
            </li>
        )
    } else if(data.specialClass === 'call'){
        return (
            <li className="mer-global-nav__menu-item mer-global-nav__menu-item--call">
                <a href={data.link} className="mer-global-nav__menu-link">
                    <Svg title="Phone ring" 
                        icon={data.svgIcon}
                        col="mer-svg-container--width-xs mer-svg-container--height-xs"
                        aria-hidden="true" />
                    {data.title}
                </a>
            </li>
        )
    } else if (data.specialClass === 'my-account') {
        return (
            <li className={`mer-global-nav__menu-item ${iconClass} mer-global-nav__menu-item--myAcct ${hidingClass}`}>
                <a href={data.link} className="mer-global-nav__menu-link">
                    <Svg title="User"
                        icon={data.svgIcon}
                        aria-hidden="true" />
                    My Account
                    <button className="mer-global-nav__accordion-button" 
                            id={`global-nav-submenu-btn-${index}`} 
                            aria-controls={`global-nav-submenu-${index}`} 
                            aria-expanded="false"
                            onClick={handleDropdownSubmenuClick}>
                        <Svg title="Chevron down" 
                            icon="chevron-down"
                            col="mer-fill--primary-royal"
                            aria-hidden="true" />
                    </button>
                </a>
                <Submenu submenuData={data.submenu} submenuClass={submenuClass} index={index} />
            </li>
        )
    }
    else{

    return (
        <li className={`mer-global-nav__menu-item ${iconClass} ${specialClass}`}>
            <a href={data.link} className="mer-global-nav__menu-link">

                {data.svgIcon &&
                    <Svg title={data.svgIcon}
                        icon={data.svgIcon}
                        aria-hidden="true" />
                    }
                {data.title}

                {data.submenu &&
                    <button className="mer-global-nav__accordion-button" 
                            id={`global-nav-submenu-btn-${index}`} 
                            aria-controls={`global-nav-submenu-${index}`} 
                            aria-expanded="false"
                            onClick={handleDropdownSubmenuClick}>
                        <Svg title="Chevron down" 
                            icon="chevron-down"
                            col="mer-fill--primary-royal"
                            aria-hidden="true" />
                    </button>
                }

            </a>
            <Submenu submenuData={data.submenu} submenuClass={submenuClass} index={index} />
        </li>
    )
            }
}

const Submenu = ({submenuData, index, submenuClass}) => {

    if(submenuData === undefined){
        return null
    }
  
    return (
        <ul className={`mer-global-nav__submenu ${submenuClass}`} 
            id={`global-nav-submenu-${index}`} 
            aria-labelledby={`global-nav-submenu-btn-${index}`}>
            {submenuData.map((data, submenuIndex) => {
                if (isLpgDemergerOn && data.hiddenByLpgDemerger !== undefined && data.hiddenByLpgDemerger === true) {
                    return null;
                } else {
                    return (
                        <li className="mer-global-nav__submenu-item" key={submenuIndex}>
                                <Link text={data.title}
                                    linkClass="mer-global-nav__submenu-link"
                                    link={data.link} />
                        </li>
                    );
                }
            })}
        </ul>
        
    );
}

const Header = ({useReactApi}) => {

    const point = useBreakpoint(); 
    const [mobileMenu, setMobileMenu] = useState(false);

    const activeClass = 'mer-global-nav--isOpen';
    const activeSubMenuClass = 'mer-global-nav__submenu--isOpen';
    const contentOffset = '[data-contentoffset="true"]';
    const merGlobalNavRef = useRef(null);
    const merGlobalNavMobileMenu = useRef(null);

    const rawData = navigationLinksData.content;
    let baseNavigation = rawData.filter(function(el) { return el.title !== "My Account"; });
    let myAcct = rawData.filter(function(el) { return el.title == "My Account"; });



    const closeMobileMenu = () => {

        // close offset and nav
        let contentOffset = document.querySelectorAll('[data-contentoffset="true"]');
        let scope = document.querySelectorAll('[data-mer-global-nav="scope"]');

        setMobileMenu(false);
        merGlobalNavMobileMenu.current.setAttribute('aria-expanded', false);

        // remove contetnIsOffset
        for (let i = 0; i < contentOffset.length; i++) {
            contentOffset[i].classList.remove('contentIsOffset');
        }

        for (let i = 0; i < scope.length; i++) {
            scope[i].classList.remove('mer-global-nav--isOpen');
        }
    }

    const handleMobileMenuClick = (e) => {

        e.preventDefault();

        let thisTarget = e.currentTarget;
        let target = e.currentTarget.nextElementSibling;
        let offsets = document.querySelectorAll(contentOffset);

        setMobileMenu(!mobileMenu);

        if (!mobileMenu) {
            thisTarget.setAttribute('aria-expanded', true);
            target.classList.add(activeClass);

            for (let i = 0; i < offsets.length; i++) {
                offsets[i].classList.add('contentIsOffset');
            }
        } else {
            closeMobileMenu();
        }
    }

    useEffect(() => {
        // console.log('Header');
        if (typeof window !== 'undefined'){
            window.onerror = function(msg, url, line, column, error){
                console.log('from header ' + msg );
            }
        }

        window.addEventListener('error', e=>{
            // Get the error properties from the error event object
            const { message, filename, lineno, colno, error } = e; 
            console.log('from header 1' + message);
        });


        // console.log(`MST.Gatsby ::: header ::: useReactApi:${useReactApi}`);

        const handleClickOutside = (event) => {

            // outside nav
            if (merGlobalNavRef.current && !merGlobalNavRef.current.contains(event.target) && !merGlobalNavMobileMenu.current.contains(event.target)) {

                closeMobileMenu();
            }

            // outside sub menu
            let activeSubMenus = document.getElementsByClassName(activeSubMenuClass);
            let navItem = '.mer-global-nav__menu-item';
            let navItems = document.getElementsByClassName('mer-global-nav__menu-item');

            if (activeSubMenus.length > 0) {

                for (let i = 0; i < activeSubMenus.length; i++) {


                    if (event.target.getAttribute('class') !== 'mer-global-nav__accordion-button' && !activeSubMenus[i].closest(navItem).contains(event.target)) {
                
                        let activeSubMenuBtn = document.querySelector(`[aria-controls="${activeSubMenus[i].getAttribute('id')}"]`);

                        if (activeSubMenuBtn !== null) {
                            activeSubMenuBtn.setAttribute('aria-expanded', false);
                        }

                        activeSubMenus[i].classList.remove(activeSubMenuClass);
                    }
                }
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useLayoutEffect(() => {
        if(point === 'large') {
            closeMobileMenu();
        }
    });

    return (
        <>
            <NoticeBar useReactApi={useReactApi} />
            <header className="mer mer-global-header" data-contentoffset="true" data-version="5">
                <div className="container-lg">
                    <div className="row">
                        <a href="/" className="mer-global-header__logo-container">
                            <h1 className="mer-display--visual-hidden">
                                Kleenheat
                            </h1>
                            <Svg title="Kleenheat" 
                                 icon="kleenheat-logo"
                                 aria-hidden="true" />
                        </a>
                        <button className="mer-global-header__menu-btn" 
                                data-slide="data-contentslide" 
                                aria-controls="mer-global-nav" 
                                aria-expanded="false" 
                                aria-label="Open menu"
                                onClick={handleMobileMenuClick}
                                ref={merGlobalNavMobileMenu}>
                            <Svg title="Hamburger" 
                                 icon="hamburger" />
                            <span className="mer-global-header__menu-btn-wording">
                                <span className="mer-display--visual-hidden mer-global-header__menu-btn-SR">Open</span> Menu
                           </span>
                        </button>
                        {<nav className="mer-global-nav" 
                             id="mer-global-nav" 
                             aria-label="Primary navigation"
                             data-mer-global-nav="scope"
                             ref={merGlobalNavRef}>
                            <ul className="mer-global-nav__menu">
                                { myAcct.map((data, index) => {
                                    return (
                                        <NavigationItem data={data} key={'myAcct-'+data.title + index} index={'myAcct-'+index} hidingClass='d-lg-none' />
                                    );
                                })}
                                { baseNavigation.map((data, index) => {
                                    return (
                                        <NavigationItem data={data} key={'other-'+data.title + index} index={'other-'+index} />
                                    );
                                })}
                                { myAcct.map((data, index) => {
                                    return (
                                        <NavigationItem data={data} key={'small-'+data.title + index} index={'small'-index} hidingClass='d-none d-lg-block' />
                                    );
                                })}
                            </ul>
                        </nav>}
                       
                    </div>
                </div>
            </header>
        </>
    );
}

  Header.propTypes = {
    siteTitle: PropTypes.string,
    useReactApi: PropTypes.bool
};

Header.defaultProps = {
    siteTitle: ``,
    useReactApi: true
};

export default Header;
